import React from "react"
import { graphql } from 'gatsby'
 
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import Pager from "../components/Pager"
import { Row}  from 'react-bootstrap'

import * as styles from '../styles/list-item.module.css'

import ArticlePreview from '../components/ArticlePreview'
const Laravel_Example_List = ({ data, pageContext }) => {

    const examples = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <Seo
                title="Laravel/PHP Tutorial"
                description="List of tutorials builds on Laravel/PHP"
            />     
            <h1>Laravel/PHP Tutorials</h1>               
            <Row className={styles.list}>
                {examples.map(item => (
               <ArticlePreview key={item.id}
                   title={item.frontmatter.title}
                   link = {"/" + item.frontmatter.slug}
                   published= {item.frontmatter.published}
                />           
            ))}
            </Row>
            <Pager pageContext={pageContext} />
        </Layout>
    )
}


export const query= graphql`
query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
        filter: {fileAbsolutePath: {glob: "**/examples/laravel/*"}}
        sort: {order: DESC, fields: frontmatter___published}
        skip: $skip,
        limit: $limit
        ) {
        nodes {
            frontmatter {
                slug
                title
                published(formatString: "MMMM Do, YYYY")
            }
            id
        }
    }
  }

`

export default Laravel_Example_List;